import { Controller } from 'stimulus';
import consumer from '../../channels/consumer';

export default class GridController extends Controller {
  static targets = ['grid', 'item', 'selectedCount', 'classroomManager'];

  get checkedCheckboxTargets() {
    return this.selectedItems.flatMap((selectedItem) => {
      const checkboxes = selectedItem.querySelectorAll('input[type=checkbox]:checked');
      return [...checkboxes];
    });
  }

  get selectedItems() {
    return this.itemTargets.filter((itemTarget) => itemTarget.dataset['thumbnail-ItemChecked'] === 'true');
  }

  // eslint-disable-next-line class-methods-use-this
  get cookieExpiryTime() {
    const date = new Date();
    return date.setFullYear(date.getFullYear() + 1);
  }

  set layout(layout) {
    this.data.set('layout', layout);
    document.cookie = `page_layout=${layout}; expires="${this.cookieExpiryTime}"`;
  }

  set zoomLevel(zoom) {
    this.data.set('zoomLevel', zoom);
    document.cookie = `zoom_level=${zoom}; expires="${this.cookieExpiryTime}"`;
  }

  get layout() {
    return this.data.get('layout');
  }

  get zoomLevel() {
    return this.data.get('zoomLevel');
  }

  connect() {
    this.itemSelected();
    this.addUserPresence();
  }

  /**
   * @public
   */
  itemSelected() {
    const selectedCount = this.selectedItems.length;
    this.selectedCountTarget.dataset.selectedCount = selectedCount;
    this.data.set('items-selected', selectedCount > 0);
    const selectEvent = new CustomEvent('grid:change', {
      bubbles: true,
      detail: {
        itemsSelected: selectedCount > 0,
        selectedCount,
      },
    });
    this.element.dispatchEvent(selectEvent);
  }

  /**
   * @public
   */
  selectAll(e) {
    e.preventDefault();
    this.itemTargets.forEach((item) => this.selectItem(item));
  }

  /**
   * @public
   */
  deselectAll(event) {
    const { type } = event;
    if (type === 'click') {
      event.preventDefault();
    }
    this.itemTargets.forEach((item) => this.deselectItem(item));
  }

  // eslint-disable-next-line class-methods-use-this
  restartAll() {
    const restartEvent = new CustomEvent('deviceSession:restartAll', { bubbles: true });
    window.dispatchEvent(restartEvent);
  }

  /**
   * @public
   */
  zoom(e) {
    const { detail: zoomLevel } = e;
    this.gridTargets.forEach((list) => {
      list.classList.remove('thumbnail__grid--sm', 'thumbnail__grid--md', 'thumbnail__grid--lg', 'thumbnail__grid--xl');
      list.classList.add(`thumbnail__grid--${zoomLevel}`);
    });
    this.zoomLevel = zoomLevel;
  }

  /**
   * @public
   */
  layoutChanged(e) {
    const { detail: layout } = e;
    this.gridTargets.forEach((list) => {
      list.classList.remove('thumbnail__grid--compact', 'thumbnail__grid--regular', 'thumbnail__grid--details');
      list.classList.add(`thumbnail__grid--${layout}`);
    });
    this.layout = layout;
  }

  /**
   * @public
   * Attaches selected items to outgoing AJAX requests
   */
   /* eslint-disable no-unused-vars */
  attachSelectedItems(event) {
    const [_xhr, options] = event.detail;
    const { data } = options;

    const checked = this.checkedCheckboxTargets;
    const additionalParams = checked.map((check) => {
      const { name, value } = check;
      return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    }).join('&');

    options.data = `${data}&${additionalParams}`;
  }
  /* eslint-enable no-unused-vars */

  /**
   * @public
   * @param customEvent
   */
  removeSelected() {
    const { selectedItems } = this;
    selectedItems.forEach((item) => {
      item.remove();
    });
  }

  /**
   * @public
   */
  rebuildLink(event) {
    const clickedLink = event.currentTarget;
    const checked = this.checkedCheckboxTargets;
    const baseUrl = clickedLink.href;

    const additionalParams = checked.map((check) => {
      const { name, value } = check;
      return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    }).join('&');

    clickedLink.href = `${baseUrl}?${additionalParams}`;
  }

  /**
   * @public
   */
  addUserPresence() {
    consumer.subscriptions.create({ channel: 'ClassSessionChannel', group_id: this.classroomManagerTarget.dataset.groupId });
  }

  /**
   * @private
   * @param thumbnailItem {HTMLInputElement}
   */
  selectItem(thumbnailItem) {
    this.dispatchChangeEvent(thumbnailItem, true);
  }

  /**
   * @private
   * @param thumbnailItem {HTMLInputElement}
   */
  deselectItem(thumbnailItem) {
    this.dispatchChangeEvent(thumbnailItem, false);
  }

  /**
   * @private
   * @param thumbnailItem {Element}
   * @param select {Boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  dispatchChangeEvent(thumbnailItem, select = true) {
    const changeEvent = new CustomEvent('thumbnail:check', { detail: { select }, bubbles: true });
    thumbnailItem.dispatchEvent(changeEvent);
  }
}
