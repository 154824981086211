import { Controller } from 'stimulus';
import * as moment from 'moment';

export default class EnrolmentController extends Controller {
  static targets = ['policySelect', 'iosResults', 'androidResults', 'spinner'];

  static iosEnrolmentData = null;

  static androidEnrolmentData = null;

  connect() {
    this.initialiseSelect2();
  }

  initialiseSelect2() {
    $(this.selects).select2({
      allowClear: true,
    });
    $(this.policySelectTarget).on('change.select2', (event) => {
      const { target } = event;
      const changeEvent = new CustomEvent('linked.change', { bubbles: true, detail: { policy: this.policySelectTarget.value } });
      target.dispatchEvent(changeEvent);
      this.changePolicy(event);
    });
  }

  iosEnrolment() {
    if (this.iosEnrolmentData == null) {
      this.spinnerTarget.classList.add('show');
      this.createEnrolment('ios');
    }
    if (this.iosResultsTarget.style.display !== 'block') {
      this.iosResultsTarget.style.display = 'block';
    } else {
      this.iosResultsTarget.style.display = 'none';
    }
    this.androidResultsTarget.style.display = 'none';
  }

  androidEnrolment() {
    const currentTime = moment();
    if (this.androidEnrolmentData == null || currentTime.isAfter(this.androidEnrolmentData.expired_at)) {
      this.spinnerTarget.classList.add('show');
      this.createEnrolment('android');
    }
    this.iosResultsTarget.style.display = 'none';
    if (this.androidResultsTarget.style.display !== 'block') {
      this.androidResultsTarget.style.display = 'block';
    } else {
      this.androidResultsTarget.style.display = 'none';
    }
  }

  changePolicy(event) {
    const { target } = event;
    if (!target) {
      return;
    }
    if (this.androidEnrolmentData != null) {
      this.createEnrolment('android');
    }
  }

  async createEnrolment(provider) {
    const params = new FormData();
    params.append('policy_id', this.policySelectTarget.value);

    let enrolmentPath = null;
    if (provider === 'ios') {
      enrolmentPath = '/mdm/apple/enrolments';
    } else {
      enrolmentPath = '/mdm/google/enrolments';
    }
    await Rails.ajax({
      type: 'POST',
      url: enrolmentPath,
      data: params,
      success: (response) => {
        this.spinnerTarget.classList.remove('show');
        if (provider === 'ios') {
          this.iosResultsTarget.innerHTML = response.enrolment.html;
          this.iosEnrolmentData = response.enrolment.data;
        } else {
          this.androidResultsTarget.innerHTML = response.enrolment.html;
          this.androidEnrolmentData = response.enrolment.data;
        }
      },
      error: (error) => {
        console.warn(error);
      },
    });
  }
}
