import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class MdmDeviceLastSyncController extends Controller {
  static targets = ['time'];

  connect() {
    const deviceId = this.data.get('id');
    const { timeTarget } = this;

    consumer.subscriptions.create(
      { channel: 'MdmDeviceSyncedChannel', device_id: deviceId },
      {
        received(data) {
          timeTarget.setAttribute('datetime', data.synced_at);
        },
      },
    );
  }
}
