import { Controller } from 'stimulus';
import * as Awesomplete from 'awesomplete';

export default class SignupController extends Controller {
  static targets = ['locationSelect', 'organisationTypeSelect', 'schoolSearch', 'schoolSelect', 'schoolList', 'schoolDetails', 'schoolIdentifier', 'schoolId', 'urnFields', 'schoolNameLabel', 'organisationNameLabel', 'schoolNameInput', 'organisationNameInput'];

  get schoolDetailInputs() {
    const textInputElements = this.schoolDetailsTarget.querySelectorAll('[data-school-field]');
    return Array.from(textInputElements);
  }

  get schoolIdentifierInputs() {
    const identifierAttributes = ['urn', 'laestab', 'ukprn'];
    return this.schoolDetailInputs.filter((input) => {
      const { schoolField } = input.dataset;
      return identifierAttributes.includes(schoolField);
    });
  }

  connect() {
    this.initializeSearch();
  }

  initializeSearch() {
    const options = {
      minChars: 0,
      autoFirst: true,
      sort: () => {
      },
      replace: (suggestion) => {
        this.schoolSelectTarget.value = null;
        this.schoolIdTarget.value = suggestion.value;
      },
    };
    this.schoolSelect = new Awesomplete(this.schoolSelectTarget, options);
  }

  async laSelected(event) {
    const { currentTarget } = event;
    const { value, options } = currentTarget;
    const { dataset } = Array.from(options).find((option) => option.value === value);
    const { url } = dataset;
    await this.loadSchools(url);
    this.clearSchoolDetails();
    this.preselectCountryDropdown(this.locationSelectTarget.value);
  }

  async schoolSelected(event) {
    const { text, currentTarget } = event;
    const { value } = text;
    const { dataset } = currentTarget;
    const { url } = dataset;
    const data = await SignupController.getSchoolData(url, value);
    this.preFillSchoolData(data);
    this.displaySchoolDetails();
  }

  locationSelected(event) {
    const { currentTarget } = event;
    const { value: location } = currentTarget;
    const { value: organisationType } = this.organisationTypeSelectTarget;
    this.toggleSearch(location, organisationType);
    this.clearSchoolDetails();
    this.preselectCountryDropdown(location);
  }

  organisationTypeSelected(event) {
    const { currentTarget } = event;
    const { value: organisationType } = currentTarget;
    const { value: location } = this.hasCountrySelectTarget ? this.locationSelectTarget : '';
    this.toggleSearch(location, organisationType);
    this.toggleUrnFields(organisationType);
    if (organisationType === 'school') {
      this.schoolNameLabelTarget.classList.add('show');
      this.schoolNameInputTarget.classList.add('show');
      this.organisationNameLabelTarget.classList.remove('show');
      this.organisationNameInputTarget.classList.remove('show');
    } else {
      this.organisationNameLabelTarget.classList.add('show');
      this.organisationNameInputTarget.classList.add('show');
      this.schoolNameLabelTarget.classList.remove('show');
      this.schoolNameInputTarget.classList.remove('show');
    }
  }

  schoolIdentifierSelected(event) {
    const { currentTarget } = event;
    const { value } = currentTarget;
    this.showSchoolIdentifier(value);
  }

  showSchoolIdentifier(identifier) {
    this.schoolIdentifierTargets.forEach((idTarget) => {
      const { dataset } = idTarget;
      const { identifier: targetIdentifier } = dataset;
      idTarget.classList.toggle('show', identifier === targetIdentifier);
    });
  }

  showSchoolDetails() {
    this.displaySchoolDetails(true);
  }

  async loadSchools(url = null) {
    if (!url) {
      this.setSchools([]);
      return;
    }

    this.schoolSelect.disabled = true;
    const response = await fetch(url);
    const json = await response.json();
    const { data } = json;
    this.setSchools(data);
    this.schoolSelect.disabled = false;
  }

  /**
   * @typedef school
   * @property uuid {string}
   * @property name {string}
   * @property urn {string}
   * @property laestab {string}
   * @property ukprn {string}
   * @property town {string}
   * @property county {string}
   * @property postcode {string}
   * @property phase {string}
   * @property address_1 {string}
   * @property address_2 {string}
   * @property telephone {string}
   */

  /**
   * @typedef apiSchool
   * @property id {string}
   * @property type {string}
   * @property attributes {school}
   */

  /**
   *
   * @param schools {apiSchool[]}
   */
  setSchools(schools = []) {
    this.schoolSelect.list = schools.map((school) => {
      const { name: label, uuid: value } = school.attributes;
      return { label, value };
    });
  }

  /**
   * @param url {string}
   * @param id {string}
   * @return {apiSchool}
   */
  static async getSchoolData(url, id) {
    const response = await fetch([url, id].join('/'));
    const json = await response.json();
    const { data } = json;
    return data;
  }

  /**
   * @param school {apiSchool}
   */
  preFillSchoolData(school) {
    const { attributes } = school;

    Object.entries(attributes).forEach(([attribute, value]) => {
      /* eslint-disable-next-line arrow-body-style */
      const input = this.schoolDetailInputs.find((textInput) => {
        return textInput.dataset.schoolField === attribute;
      });
      if (input) {
        input.value = value;
      }
    });

    this.schoolIdentifierInputs.forEach((input) => {
      /* eslint-disable-next-line no-param-reassign */
      input.readOnly = true;
    });
  }

  toggleSearch(location, organisationType) {
    if (location === 'england' && organisationType === 'school') {
      this.displaySchoolSearch(true);
      this.displaySchoolDetails(false);
    } else {
      this.displaySchoolSearch(false);
      this.displaySchoolDetails(true);
    }
  }

  toggleUrnFields(organisationType) {
    if (organisationType === 'school') {
      this.displayUrnFields(true);
    } else {
      this.displayUrnFields(false);
    }
  }

  displayUrnFields(show = true) {
    this.urnFieldsTarget.classList.toggle('show', show);
  }

  displaySchoolSearch(show = true) {
    this.schoolSearchTarget.classList.toggle('show', show);
  }

  displaySchoolDetails(show = true) {
    this.schoolDetailsTarget.classList.toggle('show', show);
  }

  clearSchoolDetails() {
    this.schoolDetailInputs.forEach((input) => {
      /* eslint-disable-next-line no-param-reassign */
      input.value = null;
    });
    this.schoolIdTarget.value = null;
    this.schoolIdentifierInputs.forEach((input) => {
      /* eslint-disable-next-line no-param-reassign */
      input.readOnly = false;
    });
  }

  preselectCountryDropdown(location) {
    const locations = {
      england: 'GB',
      northern_ireland: 'GB',
      republic_of_ireland: 'IE',
      scotland: 'GB',
    };

    const countryCode = locations[location];

    if (countryCode) {
      const countrySelect = this.schoolDetailInputs.find((ele) => ele.dataset.schoolField === 'country');

      Array.from(countrySelect).forEach((option) => {
        /* eslint-disable-next-line no-param-reassign */
        if (option.value === countryCode) option.selected = true;
      });
    }
  }
}
