import { Controller } from 'stimulus';
import * as AutoComplete from '@tarekraafat/autocomplete.js';

export default class TypeaheadController extends Controller {
  static targets = ['search', 'destination'];

  get destination() {
    if (this.hasDestinationTarget) {
      return this.destinationTarget;
    }
    return this.element;
  }

  connect() {
    this.initializeTypeahead();
    this.disableKeypressSubmission();
  }

  initializeTypeahead() {
    if (!this.hasSearchTarget) {
      return;
    }

    this.autoComplete = new AutoComplete({
      data: {
        src: async () => {
          const query = this.searchTarget.value;
          if (!this.thresholdMet(query)) {
            return [];
          }

          try {
            const baseUrl = this.data.get('url')
            const source = await fetch(`${baseUrl}?search=${query}`);
            return await source.json();
          } catch (err) {
            return [];
          }
        },
        key: ['ident'],
        cache: false,
      },
      trigger: {
        event: ['input', 'focusout'],
        condition: this.getCondition().bind(this),
      },
      selector: () => this.searchTarget,
      threshold: 2,
      debounce: 300,
      maxResults: 10,
      resultsList: {
        render: true,
        destination: this.destination,
        position: 'beforeend',
        element: 'ul',
      },
      onSelection: this.onSelection.bind(this),
    });
  }

  /**
   * @protected
   * Inheriting Controllers should override this method
   */
  getCondition() {
    return function (query) {
      return this.thresholdMet(query)
    };
  }

  thresholdMet(query) {
    return query.trim().length > this.autoComplete.threshold;
  }

  reset() {
    this.searchTarget.value = null;
  }

  disableKeypressSubmission() {
    this.searchTargets.forEach((searchTarget) => {
      searchTarget.addEventListener('keypress', (event) => {
        const { which: keyValue } = event;
        if (keyValue === 13) {
          event.preventDefault();
        }
      });
    });
  }
}
