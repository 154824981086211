import { Controller } from 'stimulus';

export default class AutomaticLoginsController extends Controller {
  editIdentifier(event) {
    const credentialId = event.target.dataset.id;
    const credentialTextElement = document.getElementById(`text_${credentialId}`);
    const credentialEditElement = document.getElementById(`edit_${credentialId}`);

    credentialTextElement.classList.add('d-none');
    credentialEditElement.classList.remove('d-none');
  }

  cancelEditCredential(event) {
    event.preventDefault();

    const credentialId = event.target.dataset.id;
    const credentialTextElement = document.getElementById(`text_${credentialId}`);
    const credentialEditElement = document.getElementById(`edit_${credentialId}`);

    credentialTextElement.classList.remove('d-none');
    credentialEditElement.classList.add('d-none');
  }
}
