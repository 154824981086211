/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import { Controller } from 'stimulus';

export default class PasswordEditController extends Controller {
  static targets = ['currentPassword', 'newPassword', 'passwordConfirmation', 'passwordStrengthGuidance', 'passwordMatchGuidance']

  toggleNewPasswordField() {
    const currentPasswordLength = this.currentPasswordTarget.value.length;
    this.newPasswordTargets.forEach((element) => {
      if (currentPasswordLength > 0) {
        element.disabled = false;
      } else {
        element.value = '';
        element.disabled = true;
        this.passwordStrengthGuidanceTarget.innerHTML = '';
        this.passwordMatchGuidanceTarget.innerHTML = '';
      }
    });
  }

  togglePasswordStrengthGuidance() {
    const currentPasswordLength = this.newPasswordTarget.value.length;
    const minimumPasswordLength = this.newPasswordTarget.dataset.passwordLength;

    if (currentPasswordLength > 0) {
      /* eslint-disable no-undef */
      if (currentPasswordLength >= minimumPasswordLength) {
        this.passwordStrengthGuidanceTarget.innerHTML = `
          <p class="password-guidance"><small><b>
            ${I18n.t('assets.javascripts.controllers.password_edit.password_strength.message')}
            <span class="password-guidance__success">${I18n.t('assets.javascripts.controllers.password_edit.password_strength.good')}</span>
          </b></small></p>
          <p class="password-guidance">
            <small><i class="fa-light fa-circle-check password-guidance__success"></i>
            ${I18n.t('assets.javascripts.controllers.password_edit.password_rule', { minimum_password_length: minimumPasswordLength })}
          </small></p>
        `;
      } else {
        this.passwordStrengthGuidanceTarget.innerHTML = `
          <p class="password-guidance"><small><b>
            ${I18n.t('assets.javascripts.controllers.password_edit.password_strength.message')}
            <span class="password-guidance__danger">${I18n.t('assets.javascripts.controllers.password_edit.password_strength.weak')}</span>
          </b></small></p>
          <p class="password-guidance"><small>
            <i class="fa-light fa-circle-xmark password-guidance__danger"></i>
            ${I18n.t('assets.javascripts.controllers.password_edit.password_rule', { minimum_password_length: minimumPasswordLength })}
          </small></p>
        `;
      }
      /* eslint-enable no-undef */
    } else {
      this.passwordStrengthGuidanceTarget.innerHTML = '';
    }
  }

  togglePasswordView(event) {
    const passwordToggle = event.currentTarget;
    const passwordField = passwordToggle.previousElementSibling;

    const type = passwordField.type === 'password' ? 'text' : 'password';
    passwordField.setAttribute('type', type);

    if (passwordToggle.classList.contains('fa-eye-slash')) {
      passwordToggle.classList.remove('fa-eye-slash');
      passwordToggle.classList.add('fa-eye');
    } else {
      passwordToggle.classList.remove('fa-eye');
      passwordToggle.classList.add('fa-eye-slash');
    }
  }

  togglePasswordMatchGuidance() {
    if (this.passwordConfirmationTarget.value.length > 0) {
      /* eslint-disable no-undef */
      if (this.passwordConfirmationTarget.value === this.newPasswordTarget.value) {
        this.passwordMatchGuidanceTarget.innerHTML = `
          <p class="password-guidance"><small><b>
            ${I18n.t('assets.javascripts.controllers.password_edit.password_match')}
            <i class="fa-light fa-circle-check password-guidance__success"></i>
          </b></small></p>
        `;
      } else {
        this.passwordMatchGuidanceTarget.innerHTML = `
          <p class="password-guidance"><small><b>
            ${I18n.t('assets.javascripts.controllers.password_edit.password_match')}
            <i class="fa-light fa-circle-xmark password-guidance__danger"></i>
          </b></small></p>
        `;
      }
      /* eslint-enable no-undef */
    } else {
      this.passwordMatchGuidanceTarget.innerHTML = '';
    }
  }
}
