import { Controller } from 'stimulus';

export default class PasscodeControlController extends Controller {
  static targets = ['passcodeState', 'passcodeContainer', 'mainContainer', 'alphanumericState', 'alphanumericContainer', 'passcodeStateContainer', 'lengthState', 'minLength', 'lengthContainer', 'numberContainer', 'numberState', 'minComplex', 'ageContainer', 'ageState', 'maxAge', 'historyContainer', 'historyLength', 'historyState'];

  connect() {
    this.checkValues();
  }

  checkValues() {
    this.PasscodeStateHandler(this.passcodeStateContainerTarget.querySelector('input[type="radio"]:checked').value);
    this.handleContainer('alphanumeric', this.alphanumericStateTarget.checked);
    this.handleContainer('length', !!this.lengthStateTarget.checked);
    this.handleContainer('number', !!this.numberStateTarget.checked);
    this.handleContainer('age', !!this.ageStateTarget.checked);
    this.handleContainer('history', !!this.historyStateTarget.checked);
  }

  validatePasscodeValues(event) {
    if (parseInt(event.target.value, 10) >= parseInt(event.target.min, 10)
      && parseInt(event.target.value, 10) <= parseInt(event.target.max, 10)) {
      event.target.classList.remove('border-danger');
    } else {
      event.target.classList.add('border-danger');
    }
  }

  passcodeStateChange(event) {
    this.PasscodeStateHandler(event.target.value);
  }

  PasscodeStateHandler(value) {
    if (value === 'enabled') {
      this.passcodeContainerTarget.classList.remove('d-none');
      this.mainContainerTarget.classList.add('border');
    } else {
      this.passcodeContainerTarget.classList.add('d-none');
      this.mainContainerTarget.classList.remove('border');
    }
  }

  alphanumericHandler(event) {
    this.handleContainer('alphanumeric', event.target.checked);
  }

  lengthHandler(event) {
    this.handleContainer('length', event.target.checked);
  }

  numberHandler(event) {
    this.handleContainer('number', event.target.checked);
  }

  ageHandler(event) {
    this.handleContainer('age', event.target.checked);
  }

  historyHandler(event) {
    this.handleContainer('history', event.target.checked);
  }

  handleContainer(name, value) {
    switch (name) {
      case 'alphanumeric':
        if (value === true) {
          this.alphanumericContainerTarget.classList.remove('text-grey');
        } else {
          this.alphanumericContainerTarget.classList.add('text-grey');
        }
        break;
      case 'length':
        if (value === true) {
          this.minLengthTarget.readOnly = false;
          this.lengthContainerTarget.classList.remove('text-grey');
        } else {
          this.minLengthTarget.readOnly = true;
          this.lengthContainerTarget.classList.add('text-grey');
        }
        break;
      case 'number':
        if (value === true) {
          this.minComplexTarget.readOnly = false;
          this.numberContainerTarget.classList.remove('text-grey');
        } else {
          this.minComplexTarget.readOnly = true;
          this.numberContainerTarget.classList.add('text-grey');
        }
        break;
      case 'age':
        if (value === true) {
          this.maxAgeTarget.disabled = false;
          this.ageContainerTarget.classList.remove('text-grey');
        } else {
          this.maxAgeTarget.disabled = true;
          this.ageContainerTarget.classList.add('text-grey');
        }
        break;
      case 'history':
        if (value === true) {
          this.historyLengthTarget.disabled = false;
          this.historyContainerTarget.classList.remove('text-grey');
        } else {
          this.historyLengthTarget.disabled = true;
          this.historyContainerTarget.classList.add('text-grey');
        }
        break;
      // no default
    }
  }
}
